<template>
  <section>
    <v-form ref="forgetForm">
      <v-text-field
        v-model="email"
        outlined
        placeholder="Email"
        :rules="emailRules"
      ></v-text-field>

      <v-btn loading v-if="loading"></v-btn>
      <v-btn large class=" mb-3" v-if="!loading" @click="validate"
        >Forget Password <v-icon> mdi-chevron-double-right </v-icon
        ></v-btn
      >
    </v-form>
    <v-snackbar v-model="snackbar" :color="color"> {{ message }}</v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ForgetPasswordForm",
  data: () => ({
    email: "",
    loading: false,
    snackbar: false,
    message: "",
    color: "black",
    emailRules: [(v) => !!v || "Email is required."],
  }),
  methods: {
    ...mapActions(["postForgetPassword"]),
    async validate() {
      if (this.$refs.forgetForm.validate()) {
        this.loading = true;
        let data = {};
        data.email = this.email;
        await this.postForgetPassword(data);
        if (this.allForgetPassword.status == 200) {
          this.$router.push("/forgetpasswordverify");
        } else {
          this.snackbar = true;
          this.message = this.allForgetPassword.data.message;
          this.color = "red";
        }
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allForgetPassword"]),
  },
};
</script>
<style lang="scss" scoped>
.v-btn {
  width: 100% !important;
  background: #0878d4 !important;
  min-height: 55px;
}
</style>